window.addEventListener(
  'DOMContentLoaded',
  () => {
    new Swiper('.swiper-container.-base', {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      spaceBetween: 18,
      slidesPerView: 'auto',
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      autoResize: true,
      loop: true,
      autoplay: { delay: 4500 },

      breakpoints: {
        450: {
          slidesPerView: 1,
        },
      },
    });

    new Swiper('.swiper-container.-jobs', {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      spaceBetween: 18,
      slidesPerView: 'auto',
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      autoResize: true,
      loop: true,

      breakpoints: {
        450: {
          slidesPerView: 1,
        },
      },
    });

    new Swiper('.swiper-container.-attention-jobs', {
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: { delay: 4500 },
      spaceBetween: 18,
      slidesPerView: 'auto',
      slidesOffsetBefore: 0,
      slidesOffsetAfter: 0,
      autoResize: true,
      loop: true,

      breakpoints: {
        450: {
          slidesPerView: 1,
        },
      },
    });
  },
  false
);
